@import "/src/components/variables/variables.scss";

.main-container{
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  @media (min-width: 0px){
    .main-container{
      min-height: 31em;
      margin: 2em 0 0 0;
    }
  }

  @media (min-width: $breakpoint-mobile){
    .main-container{
      height: 100%;
      margin:auto 0px;
    }
  }
  
  @media (min-width: $breakpoint-tablet){
   
  }
  
  @media (min-width: $breakpoint-desktop){
   
  }
  
  @media (min-width: $breakpoint-large){
  
  }
  