@import '../variables/variables.scss';

.weather-instance {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    position: relative;
    color: $text-main;
    background-size: cover;
    background-position: center;
    height: 30em;
    padding:0 20px;
    box-shadow: 10px 5px 5px rgba(0, 0, 0, 0.7);
    border-radius: 15px;
    margin-bottom: 20px;
    margin-right: 20px;
  }
  

  .weather, .low-high, .date{
    font-size: 20px;
    text-shadow: 2px 3px rgba(0, 0, 0, 0.6);
  }

  .city, .temp {
    text-shadow: 2px 5px rgba(0, 0, 0, 0.6);
  }
  
  .city{
    font-size: 32px;
    font-weight: 500;
  }
  
  .temp{
    font-size: 70px;
    font-weight: 800;
  }
  
  .delete{
    position: absolute;
    top: 15px;
    right: 15px;
    background: transparent;
    border: none;
    cursor: pointer;
    
    .delete-icon {
      font-size: 25px;
      color:rgba(255,255,255,0.4);
  
      &:hover{
        color: rgba(255,255,255,1);
      }
    }
  }
  