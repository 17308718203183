@import "/src/components/variables/variables.scss";

body{
    &.light{
        .rec-arrow{
            background: transparent;
            color: $dark-red;
            border: 2px solid $dark-red;
            &:hover{
                background: rgba($light-hover, 0.5);
            }
        }
        .rec-pagination{
            .rec-dot{
                box-shadow: 0 0 1px 3px $dark-red;
                &_active{
                    background: rgba($light-hover, 0.5);
                }
            }
        }
    }
  }
  
  body{
    &.dark{
        .rec-arrow{
            background: transparent;
            color: $secondary-dark;
            border: 2px solid $secondary-dark;
            &:hover{
                background: rgba($dark-hover, 0.5);
            }
        }
        .rec-pagination{
            .rec-dot{
                box-shadow: 0 0 1px 3px $secondary-dark;
                &_active{
                    background: rgba($dark-hover, 0.5);
                }
            }
        }
    }
  }
  
  @media (min-width: 1px){
    .rec-arrow{
        display: none;
    }
  }
  
  @media (min-width: $breakpoint-mobile){
    .rec-arrow{
        display: block;
        margin:20px;
    }
  }
  
  @media (min-width: 1700px){
    .rec-arrow, .rec-dot{
        display: none;
    }
  }
  
