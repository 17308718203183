@import "/src/components/variables/variables.scss";
$size: 30px;

.error {
  margin: auto;
  position: absolute;
  top: 150px; left: 0; bottom: 0; right: 0;
  z-index: 1;
  width: fit-content;
  height: fit-content;
  font-size: 14px;
  font-weight: bold;
  background-color: rgba(199, 19, 19, 0.5);
  border: solid 2px rgb(255, 81, 0);
  border-radius: 10px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.7);
  padding: 5px 10px;
  color: $text-main;
  text-shadow: 2px 1px rgba(0, 0, 0, 0.6);
}

*{
  margin:0;
  padding:0;
}

body{
  &.light{
    .search-container{
      background-color: $secondary-light;
    }
    .search-bar{
      border-color: $primary-light;
    }
    .submit{
      color: $primary-light;
    }
    .input{
      color: $primary-light;
      &::placeholder{
      color: $primary-light;
      }
    }
    .search-bar:focus-within{
      .submit{
        background: $primary-light;
        color: white;
        &:focus, &:hover{
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.75);
        }
      }
    }
  }
}

body{
  &.dark{
    .search-container{
      background-color: $primary-dark;
    }
    .search-bar{
      border-color: $secondary-dark;
    }
    .submit{
      color: $secondary-dark;
    }
    .input{
      color: $secondary-dark;
      &::placeholder{
        color: $secondary-dark;
      }
    }
    .search-bar:focus-within{
      .submit{
        background: $secondary-dark;
        color: $primary-dark;
        &:focus, &:hover{
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.75);
        }
      }
    }
  }
}

@media (min-width: 0px){
  
  .search-container{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 5rem;
    background-color: $secondary-light;
    box-shadow: 0px 1px 4px black;
  }

  .search-bar{
    margin: 10px;
    display: flex;
    align-items: center;
    width: 75%;
    border: 2px solid;
    border-radius: 40px;
    height: $size;
    width: $size;
    padding: 2px;
    position: relative;
    transition: width 300ms ease-in-out;
    overflow: hidden;
  }

  .input{
    flex-grow: 1;
    font-size: 1rem;
    padding: 0 0.5em;
    border:0;
    position: absolute;
    top:0;
    bottom: 0;
    line-height: 28px;
    cursor: pointer;
    opacity: 0;
    background: transparent;

    &:focus{
      outline:0;
    }
  }

  .submit{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    cursor: pointer;
    border:0;
    background:transparent;
    border-radius: 50%;
    width: $size;
    height: $size;
    margin-left: auto;
    transition: background 150ms ease-in-out ;
  }

  .search-bar:focus-within{
    width: 75%;

    .submit{
      background: $primary-light;
      color: white;
      &:focus, &:hover{
        outline: 0;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.75);
      }
    }

    .input{
      cursor: text;
      opacity: 1;
      width: 78%;
    }
  }
  
}

@media (min-width: $breakpoint-mobile){
  .search-bar:focus-within{
    width: 20rem;
  }
}

@media (min-width: $breakpoint-tablet){
 
}

@media (min-width: $breakpoint-desktop){
 
}

@media (min-width: $breakpoint-large){

}
