@import "/src/components/variables/variables.scss";

#darkMode {
    margin:0px 10px;
    z-index: 1;
    background: transparent url("./images/darkModeIcon.png") no-repeat center;
    background-size: 20px 20px;
    width: 35px;
    height: 35px;
    filter: grayscale(100%);
    border: none;
    border-radius: 50%;
    transition: background-color 0.3s ease-in-out, filter 0.3s ease-in-out;
    outline: none;
}

#darkMode {
    &:hover, &:focus{
        filter: none;
        background-color: $dark-blue;
        cursor: pointer;
    }
}

#darkMode.clicked {
    filter: none;
    background-color: $dark-blue;
    border:solid 2px gold;
}