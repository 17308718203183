@import "../variables/variables.scss";

body {
  margin: 0;
  position: relative;
  font-family: $main-font;
  background-size: cover;

  &.light{
    background: $light-background;


    .weather-instance{
      background-image: url('./images/city1.jpg');
    }

    .header-container{
      input{
        background-color: rgba(255, 255, 255, 0.3);
        &:focus{
          background-color: rgba(255, 255, 255, 0.7);
        }
      }

    }
  }

  &.dark{
    background: $dark-background;

    .weather-instance{
      background-image: url('./images/night2.jpg');
    }

    .header-container{
      input{
        background-color: rgba(255, 255, 255, 0.7);
        &:focus{
          background-color: rgba(255, 240, 173, 0.7);
        }
      }

    }
  }
}


.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100vh;
}

